<template>
  <div>
    <div class="newCon container">
      <template v-if="isMobile(true)">
        <b-row>
          <b-col>
            <b-nav-item
              :to="
                '/news'
              "
              class="FullSchedule"
            >
              Back To All News
            </b-nav-item>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h1 class="entry-title">
              {{ postInfo[0].title.rendered }}
            </h1>
          </b-col>
        </b-row>
      </template>
      <template v-else>
        <b-row>
          <b-col>
            <h1 class="entry-title">
              {{ postInfo[0].title.rendered }}
            </h1>
          </b-col>
          <b-col>
            <b-nav-item
              :to="
                '/news'
              "
              class="FullSchedule"
            >
              Back To All News
            </b-nav-item>
          </b-col>
        </b-row>
      </template>
      <b-row>
        <b-col>
          <!-- <div class="postedon">
            Posted on {{postInfo.}}
          </div> -->
          <div class="postImg">
            <b-img :src="postInfo[0].better_featured_image.source_url" />
          </div>
          <b-row class="pad">
            <b-col>
              <p class="publishedData">Published: {{ dateTime }}</p>
            </b-col>
          </b-row>
          <div class="wpStory">
            <span v-html="postInfo[0].content.rendered"></span>
          </div>
        </b-col>
      </b-row>
    </div>
    <div>
      <Footer :white="white" :config="config" />
    </div>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
import Footer from "@/components/footer.vue";

export default {
  name: "postFull",
  props: ["config"],
  components: { Footer },
  data() {
    return {
      posts: [],
      postInfo: [],
      sidebar: true,
      id: [],
      white: false,
    };
  },
  computed: {
    dateTime: function() {
      var dateOG = this.postInfo[0].date.split("T");
      var splitDate = dateOG[0].split("-");
      var newDate = splitDate[2] + "/" + splitDate[1] + "/" + splitDate[0];
      var time = dateOG[1];
      return newDate + " - " + time;
    },
  },
  methods: {
    isMobile() {
      if( screen.width <= 500 ) {
        return true;
      }
      else {
        return false;
      }
    },
    changeStory: function(event) {
      var id = event.target.getAttribute("title");
      var json = "wp/v2/posts?slug=" + id;
      return (
        (this.id = id),
        apiCall
          .wpInfo(json)
          .then(({ data }) => {
            this.postInfo = data;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            throw error;
          })
      );
    },
  },
  async mounted() {
    var one = "wp/v2/posts/";
    var two = "wp/v2/posts?slug=" + this.$route.params.id;
    try {
      // First call, await for call to return
      let first_call = await apiCall.wpInfo(one);
      this.posts = first_call.data;
      this.loading = false;
      // Second call, await for call to return
      let second_call = await apiCall.wpInfo(two);
      this.postInfo = second_call.data;
      this.loading = false;
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
  },
};
</script>

<style scoped>
.FullSchedule > a {
  background: #EB0A1E;
  border: none;
  color: #fff !important;
  padding: 10px 50px 10px 50px;
  float: right;
  margin-top: 1.2em;
  margin-right: 1em;
  font-size: 11pt;
  width: 300px;
}
.FullSchedule > a:hover {
  background: #B91B29;
  border: none;
  color: #fff !important;
  padding: 10px 50px 10px 50px;
  float: right;
  margin-top: 1.2em;
  margin-right: 1em;
  font-size: 11pt;
  width: 300px;
}
.ReadStory:hover {
  background-color: #3E3E3E;
}
h4.postTitleSide {
  margin-top: 15px;
  margin-bottom: 15px;
}
h1.entry-title {
  font-size: 2rem;
  color: #000000;
  font-weight: 400;
  margin-bottom: 30px;
  padding-bottom: 10px;
  font-weight: 700;
  margin-top: 19px;
}
.ReadStory {
  text-transform: uppercase;
}
.postImg {
  overflow: hidden;
  height: 500px;
}

.postImg > img {
  width: 100%;
}

.wpStory {
  padding-top: 2%;
}

.newCon {
  padding: 3%;
}
::v-deep .nav-link {
  color: white;
  background-color: #EB0A1E;
  width: 40%;
  text-align: center;
}
.story {
  padding: 1%;
  margin-bottom: 30px;
}
.side {
  color: white;
  background-color: #EB0A1E;
  width: 100%;
  text-align: center;
  border-radius: 0px;
}
.imgSize {
  overflow: hidden;
  /* max-height: 212px; */
}
.newsImgSide {
  width: 100% !important;
  margin: 0;
  height: auto !important;
}

.art-images {
  overflow: hidden;
  height: 500px;
}
.space {
  padding-bottom: 2%;
  border-bottom: 1px solid lightgrey;
  padding-top: 2%;
}
@media only screen and (max-width: 768px) {
  .postImg {
    overflow: hidden;
    height: 300px;
  }
  ::v-deep figure.wp-caption.alignnone > img {
    width: 100%!important;
    height: auto;
  }
}

@media only screen and (max-width: 500px) {

  .FullSchedule > a {
    background: #EB0A1E;
    border: none;
    color: #fff !important;
    text-transform: uppercase;
    padding: 10px 50px 10px 50px;
    float: none;
    margin-top: 1.2em;
    margin-right: 1em;
    font-size: 11pt;
    width: 100%;
  }
  .ReadStory:hover {
    background-color: #3E3E3E;
  }
}
@media only screen and (max-width: 480px) {
  .col {
    flex-basis: auto;
  }
  ::v-deep .nav-link {
    width: 30%;
    text-align: center;
  }
  ::v-deep .row {
    padding-bottom: 4%;
  }
  h1.entry-title {
    font-size: 2rem;
  }
}
</style>
