<template>
  <div>
    <div class="pagePad">
      <b-row>
        <b-col class="emptyCol"> </b-col>
        <b-col>
          <b-nav-item
            :to="
              '/tournament/' +
                tmparams.code +
                '/' +
                tmparams.tm_params.season_code
            "
            class="FullSchedule"
          >
            Back To Tour Info
          </b-nav-item>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="over">
            <template v-if="tmparams.course_image_file_1.length > 0">
              <div class="bkimageTourn" v-bind:style="{ 'background-image': 'url(' + DEFIMAGESAMS3 + 'mst' + '/' + 'courses' + '/' + id + '/' + tmparams.course_code + '/' + tmparams.course_image_file_1 + ')' }"></div>
              <!-- <b-img
                :class="[splash]"
                style="width:100%;"
                :src="
                  'https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2020/05/sliderdummycourse.png'
                "
              >
              </b-img> -->
            </template>
            <template v-else>
              <b-img
                :class="[splash]"
                style="width:50%;"
                :src="
                  'https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2020/05/sliderdummycourse.png'
                "
              >
              </b-img>
            </template>
          </div>
        </b-col>
      </b-row>
      <template v-if="isMobile(true)">
        <b-row class="mobLogo">
          <b-col class="headPadR">
            <!-- <b-img class="sponsorsLogo" :src="tmparams.sponsor_logo"></b-img> -->
            <!-- <b-img class="sponsorsLogo" :src="tmparams.sponsor_logo"></b-img> -->
            <b-img
              class="sponsorsLogo"
              :src="tmparams.sponsor_logo"
              onerror="this.src='https://mst.ocs-mst.com/pmws-logos/logos/tourlogo.jpg';"
            />
            <!-- <b-img class="sponsorsLogo" :src="tmparams.sponsor_logo.replace('http://info.tartanprotour.co.uk/', this.DEFIMAGESAMS3 + 'tpt' + '/').replace('https://info.tartanprotour.co.uk/', this.DEFIMAGESAMS3 + 'tpt' + '/')" /> -->
          </b-col>
        </b-row>
        <b-row>
          <b-col class="headPadL">
            <b-row>
              <b-col>
                <h2 class="bold">
                  {{ tmparams.full_name }}
                </h2>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <h2 class="courseName">
                  {{ tmparams.course_name }}
                </h2>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <h2 class="nonbold">
                  {{ tmparams.tourn_dates }}
                </h2>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </template>
      <template v-else>
        <b-row>
          <b-col class="headPadL">
            <b-row>
              <b-col>
                <h2 class="bold">
                  {{ tmparams.full_name }}
                </h2>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <h2 class="courseName">
                  {{ tmparams.course_name }}
                </h2>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <h2 class="nonbold">
                  {{ tmparams.tourn_dates }}
                </h2>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="headPadR">
            <!-- <b-img class="sponsorsLogo" :src="tmparams.sponsor_logo"></b-img> -->
            <!-- <b-img class="sponsorsLogo" :src="tmparams.sponsor_logo"></b-img> -->
            <b-img
              class="sponsorsLogo"
              :src="tmparams.sponsor_logo"
              onerror="this.src='https://mst.ocs-mst.com/pmws-logos/logos/tourlogo.jpg';"
            />
            <!-- <b-img class="sponsorsLogo" :src="tmparams.sponsor_logo.replace('http://info.tartanprotour.co.uk/', this.DEFIMAGESAMS3 + 'tpt' + '/').replace('https://info.tartanprotour.co.uk/', this.DEFIMAGESAMS3 + 'tpt' + '/')" /> -->
          </b-col>
        </b-row>
      </template>

      <div class="drop">
        <b-nav card-header pills>
          <b-dropdown
            v-if="Array.isArray(tmparams.reports.reports_entry)"
            :text="title"
            class="dropdownReports"
          >
            <b-dropdown-item
              v-on:click="changeReport"
              v-for="(reports, index) in filterExemptions"
              :key="index"
              :title="reports.report_url"
              :value="reports.report_title"
              v-show="reports.report_title !== 'Live Scoring Monitor'"
              >{{ reports.report_title }}</b-dropdown-item
            >
          </b-dropdown>
          <b-dropdown
            v-else
            text="Please Select a Report"
            class="dropdownReports"
          >
            <b-dropdown-item
              v-on:click="changeReport"
              v-for="(reports, index) in tmparams.reports"
              :key="index"
              :text="title"
              :value="reports.report_title"
              >{{ reports.report_title }}</b-dropdown-item
            >
          </b-dropdown>
        </b-nav>
      </div>
      <div class="reports">
        <div
          v-if="
            this.currentReport.indexOf('tmentry') !== -1 ||
              this.currentReport.indexOf('tmdraw') !== -1 ||
              (this.currentReport.indexOf('tmscores') !== -1 &&
                data.match_play === 'Y') ||
              this.currentReport.indexOf('tmresult') !== -1 ||
              this.currentReport.indexOf('tmoomt') !== -1
          "
        >
          <ReportTable
            :data="data"
            :previousData="previousData"
            :home="home"
            :title="title"
            :config="config"
            :team="teamEvent"
            :match="data.match_play"
          />
        </div>
        <div
          v-else-if="
            this.currentReport.indexOf('tmlsmon') !== -1 ||
              this.currentReport.indexOf('tmscores') !== -1
          "
        >
          <b-tabs content-class="mt-3">
            <b-tab title="Leaderboard" active>
              <ReportTable
                :data="data"
                :previousData="previousData"
                :home="home"
                :title="title"
                :config="config"
                :team="teamEvent"
              />
            </b-tab>
            <b-tab title="Hole by Hole">
              <HBH
                :tabs="tabs"
                :config="config"
                :data="data"
                :scores="data.scores.scores_entry"
                :title="title"
              />
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
    <div id="footer">
      <Footer :white="white" :config="config" />
    </div>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
import HBH from "@/ocs/reports/scores/hole-by-hole/components/hbh.vue";
import Footer from "@/components/footer.vue";
import ReportTable from "@/components/reportTable.vue";

export default {
  name: "Reports",
  components: {
    HBH,
    ReportTable,
    Footer
  },
  props: ["config"],
  data() {
    return {
      data: [],
      previousData: [],
      tmparams: [],
      drop: "drop",
      currentReport: this.$route.query.url,
      home: false,
      tabs: true,
      title: this.$route.query.title,
      white: true,
      id: this.$route.query.id,
      code: this.$route.query.code
    };
  },
  methods: {
    isMobile() {
      if( screen.width <= 500 ) {
        return true;
      }
      else {
        return false;
      }
    },
    changeReport: function(event) {
      var report = event.target.getAttribute("title");
      var title = event.target.getAttribute("value");
      this.title = title;
      return (
        (this.currentReport = report),
        apiCall
          .report(this.reportTitle)
          .then(({ data }) => {
            this.data = data;
            this.loading = false;
          })
          .catch(error => {
            console.log(error);
            throw error;
          })
      );
    },
    updateStuff: function() {
      apiCall
        .tmParams(this.id, this.code)
        .then(({ data }) => {
          this.tmparams = data;
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
          throw error;
        });
    }
  },
  computed: {
    filterExemptions: function() {
      return this.tmparams.reports.reports_entry.filter(
        reports_entry => !reports_entry.report_title.includes("Statistics")
      );
    },
    teamEvent: function(team) {
      var array = this.data;
      if (array["team_scores"] !== undefined) team = "Y";
      else team = "N";
      return team;
    },
    reportTitle: function(url) {
      if (this.currentReport.indexOf("tmentry") !== -1) {
        url =
          process.env.VUE_APP_APIFULL_URL +
          this.$route.query.id +
          "/" +
          this.$route.query.id +
          "-" +
          this.$route.query.code +
          "-entry-entries.json?randomadd=" +
          new Date().getTime();
      } else {
        if (this.currentReport.indexOf("tmscore") !== -1) {
          var subRep2 = this.currentReport.lastIndexOf("params=") + 7;
          var indNext2 = this.currentReport.indexOf("~", subRep2);
          var repParams = this.currentReport.substr(
            subRep2,
            indNext2 - subRep2
          );

          if (repParams && repParams.indexOf(".cgi") < 0) {
            url =
              this.config.VUE_APP_API +
              this.config.VUE_APP_ID +
              "/cache/" +
              this.config.VUE_APP_ID +
              "/" +
              this.$route.query.id +
              "/" +
              this.$route.query.id +
              "-" +
              this.$route.query.code +
              "-scores-" +
              repParams +
              ".json" +
              "?randomadd=" +
              new Date().getTime();
          } else {
            url =
              this.config.VUE_APP_API +
              this.config.VUE_APP_ID +
              "/cache/" +
              this.config.VUE_APP_ID +
              "/" +
              this.$route.query.id +
              "/" +
              this.$route.query.id +
              "-" +
              this.$route.query.code +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
          }
        } else if (this.currentReport.indexOf("tmlsmon") !== -1) {
          {
            url =
              this.config.VUE_APP_API +
              this.config.VUE_APP_ID +
              "/cache/" +
              this.config.VUE_APP_ID +
              "/" +
              this.$route.query.id +
              "/" +
              this.$route.query.id +
              "-" +
              this.$route.query.code +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
          }
        } else if (this.currentReport.indexOf("tmdraw") !== -1) {
          var subRep = this.currentReport.lastIndexOf("round=") + 6;
          var indNext = this.currentReport.indexOf("~", subRep);
          var drawRnd = this.currentReport.substr(subRep, indNext - subRep);
          url =
            this.config.VUE_APP_API +
            this.config.VUE_APP_ID +
            "/cache/" +
            this.config.VUE_APP_ID +
            "/" +
            this.$route.query.id +
            "/" +
            this.$route.query.id +
            "-" +
            this.$route.query.code +
            "-draw-R" +
            drawRnd +
            "-draw.json" +
            "?randomadd=" +
            new Date().getTime();
        } else if (this.currentReport.indexOf("tmresult") !== -1) {
          var subRep1 = this.currentReport.lastIndexOf("result=") + 7;
          var indNext1 = this.currentReport.indexOf("~", subRep1);
          var resNum1 = this.currentReport.substr(subRep1, indNext1 - subRep1);
          console.log("resNum === ");
          console.log(resNum1);
          url =
            this.config.VUE_APP_API +
            this.config.VUE_APP_ID +
            "/cache/" +
            this.config.VUE_APP_ID +
            "/" +
            this.$route.query.id +
            "/" +
            this.$route.query.id +
            "-" +
            this.$route.query.code +
            "-result-result-" +
            resNum1 +
            ".json" +
            "?randomadd=" +
            new Date().getTime();
        } else {
          url =
            this.config.VUE_APP_API +
            this.config.VUE_APP_ID +
            "/cache/" +
            this.config.VUE_APP_ID +
            "/" +
            this.$route.query.id +
            "/" +
            this.$route.query.id +
            "-" +
            this.$route.query.code +
            "-result-result-PF.json" +
            "?randomadd=" +
            new Date().getTime();
        }
      }
      return url;
    }
  },
  created() {
    setInterval(this.updateStuff, 120000);
  },
  async mounted() {
    try {
      // First call, await for call to return
      let first_call = await apiCall.report(this.reportTitle);
      this.data = first_call.data;
      this.loading = false;
      // Second call, await for call to return
      let second_call = await apiCall.tmParams(this.id, this.code);
      this.tmparams = second_call.data;
      this.DEFIMAGESAMS3 = second_call.data.tm_params.DEFIMAGESAMS3;
      console.log("this.DEFIMAGESAMS3");
      console.log(this.DEFIMAGESAMS3);
      this.loading = false;
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
  }
};
</script>

<style scoped>
.bkimageTourn {
  height: 300px;
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  background-position: center;
}
::v-deep .nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  margin-bottom: 0 !important;
}
::v-deep h2.title {
  background: transparent !important;
  color: #3E3E3E !important;
}
::v-deep h2.title.suppliersText {
  background: transparent !important;
  color: #3E3E3E !important;
}
h2.courseName {
  font-weight: 400;
  margin-top: 25px;
  margin-bottom: 10px;
}
.sponsorsLogo {
  float: right;
  width: 200px;
}
.splash {
  width: 100%;
}
h2 {
  font-size: 24px;
}
.bold {
  font-weight: 700;
}
.headPadL {
  /* padding-left: 10%; */
  padding-top: 5%;
}
.headPadR {
  padding-top: 5%;
}
.pagePad {
  padding: 2% 5%;
}
#tourTitle {
  padding: 2%;
}
::v-deep .title {
  padding-left: 2%;
  background-color: #000;
  color: white;
  padding-right: 2%;
}
::v-deep .mt-3 {
  margin-top: 0 !important;
}
.over {
  height: 300px;
  overflow: hidden;
  margin-top: 2.8em;
}
li.nav-item.FullSchedule {
  margin-top: -25px;
}
.FullSchedule > a {
  background: #EB0A1E;
  border: none;
  color: #fff !important;
  padding: 10px 50px 10px 50px;
  float: right;
  margin-top: 3.2em;
  margin-right: 1em;
  font-size: 11pt;
  width: 300px;
}
.FullSchedule > a:hover {
  background: #B91B29;
  width: 300px;
}
::v-deep .nav-tabs {
  border-bottom: 0px solid #dee2e6;
}
::v-deep .tabsScores > div > ul {
  border-radius: 0px;
}
::v-deep .tabsScores > div > ul > li > a {
  color: #fff !important;
  font-size: 13pt;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
}
::v-deep .tabsScores > div > ul > li {
  background-color: #3E3E3E;
}
::v-deep .nav-link.active {
  color: white !important;
  background-color: #000 !important;
  border-color: #000 !important;
  height: 100%;
  border-radius: 0px;
}
.drop {
  padding: 35px 10px;
}
::v-deep .dropdown-toggle {
  background-color: #EB0A1E;
  border-color: #EB0A1E;
  border-radius: 0;
  width: 300px;
}
::v-deep .dropdown-menu {
  width: 300px;
}
::v-deep .dropdown-toggle::after {
  margin-left: 15px!important;
}
::v-deep .dropdown-toggle:hover {
  background-color: #B91B29;
  border-color: #B91B29;
}
::v-deep .dropdown.show > .dropdown-toggle {
  background-color: #B91B29;
  border-color: #B91B29;
  border-radius: 0;
}
.sponsor {
  margin-top: -10%;
  width: 20%;
  padding-left: 10%;
}
::v-deep .nav-link.active {
  color: white !important;
  background-color: #000 !important;

  border-color: #000 !important;
  height: 100%;
}

::v-deep .nav-link {
  color: black !important;

  font-size: 12pt;
}

::v-deep li.nav-item {
  text-align: center;
  margin-right: 5px;
}
::v-deep .mt-3 {
  margin-top: 0 !important;
}
@media only screen and (max-width: 500px) {
  .over {
    height: auto!important;
    overflow: hidden;
    margin-top: 2.8em;
  }
  .mobLogo {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 480px) {
  .emptyCol {
    display: none;
  }
  .sponsor {
    margin-top: 2%;
    width: 35%;
    padding-left: 4%;
  }
  .drop {
    padding-left: 3%;
    margin-top: -5%;
  }
  .FullSchedule > a {
    margin-right: 0px;
    width: 100%;
  }
  .sponsorsLogo {
    float: left;
  }
}
</style>
