<template>
  <div class="tour">
    <div class='pad'>
      <b-row class="titleandBtn">
        <b-col class="fullnameMob">
          <h2 id="tourTitle">
            {{ tourinfo.full_name }}
          </h2>
        </b-col>
        <b-col>
          <a href="/schedule">
            <button class="FullSchedule">Back To Full Schedule</button>
          </a>
        </b-col>
      </b-row>
      <div class='over'>
        <template v-if="tourinfo.course_image_file_1.length > 0">
          <div class="bkimageTourn" v-bind:style="{ 'background-image': 'url(' + DEFIMAGESAMS3 + 'mst' + '/' + 'courses' + '/' + id + '/' + tourinfo.course_code + '/' + tourinfo.course_image_file_1 + ')' }">

          </div>
          <!-- <b-img
            :class="[splash]"
            style="width:100%"
            :src="DEFIMAGESAMS3 + 'mst' + '/' + 'courses' + '/' + id + '/' + tourinfo.course_code + '/' + tourinfo.course_image_file_1"
          >
          </b-img> -->
          <!-- :src="DEFIMAGESAMS3 + 'tpt' + '/' + 'courses' + '/' + id + '/' + tourinfo.course_code + '/' + tourinfo.course_image_file_1" -->
        </template>
        <template v-else>
          <b-img
            :class="[splash]"
            style="width:100%"
            :src="'https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2020/05/sliderdummycourse.png'
            "
          >
          </b-img>
        </template>
      </div>
    </div>
    <div class="pad">
      <b-row class='MainInfo'>
        <b-col class="colwidth-mob">
          <b-row class="rowPad">
            <b-col lg="4" v-if="tourinfo.dates.length > 0">
              <h3 class="underLine">
                Dates:
              </h3>
            </b-col>
            <b-col lg="8" v-if="tourinfo.dates.length > 0">
             <h3 class="tournInfoBold">
                {{ tourinfo.dates }}
              </h3>
            </b-col>
          </b-row>
          <b-row class="rowPad">
            <b-col lg="4" v-if="tourinfo.rounds.length > 0">
              <h3>
                <span class="underLine">Rounds:</span>
              </h3>
            </b-col>
            <b-col lg="8" v-if="tourinfo.rounds.length > 0">
              <h3 class="tournInfoBold">{{ tourinfo.rounds }}</h3>
            </b-col>
          </b-row>
          <b-row class="rowPad">
            <b-col lg="4" v-if="tourinfo.total_prize_fund.length > 0">
              <h3>
                <span class="underLine">Prize Fund:</span
                >
              </h3>
            </b-col>
            <b-col lg="8" v-if="tourinfo.total_prize_fund.length > 0">
              <h3 class="tournInfoBold">{{ tourinfo.total_prize_fund }}</h3>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="colwidth-mob">
          <b-row class="rowPad">
            <b-col lg="4" v-if="tourinfo.course_name.length > 0">
              <h3 class="underLine">
                Course Name:
              </h3>
            </b-col>
            <b-col lg="8" v-if="tourinfo.course_name.length > 0">
              <h3 class="tournInfoBold">
                {{ tourinfo.course_name }}
              </h3>
            </b-col>
          </b-row>
          <b-row class="rowPad">
            <b-col lg="4" v-if="tourinfo.course_city.length > 0">
              <h3 class="underLine">
                City:
              </h3>
            </b-col>
            <b-col lg="8" v-if="tourinfo.course_city.length > 0">
              <h3 class="tournInfoBold">
                {{ tourinfo.course_city }}
              </h3>
            </b-col>
          </b-row>
          <b-row class="rowPad">
            <b-col lg="4" v-if="tourinfo.course_country.length > 0">
              <h3 class="underLine">
                Country:
              </h3>
            </b-col>
            <b-col lg="8" v-if="tourinfo.course_country.length > 0">
              <h3 class="tournInfoBold">
                {{ tourinfo.course_country }}
              </h3>
            </b-col>
          </b-row>
          <b-row class="rowPad">
            <b-col lg="4" v-if="tourinfo.sponsor_website.length > 0">
              <h3 class="underLine" >
                Website:
              </h3>
            </b-col>
            <b-col lg="8" v-if="tourinfo.sponsor_website.length > 0">
              <h3 class="tournInfoBold">
                <a :href="'https://' + tourinfo.course_website">
                  {{ tourinfo.course_website }}
                </a>
              </h3>
            </b-col>
          </b-row>
          <b-row class="rowPad">
            <b-col lg="4" v-if="tourinfo.winner_found === 'Y'">
              <h3 class="underLine" >
                Winner:
              </h3>
            </b-col>
            <b-col lg="8" v-if="tourinfo.winner_found === 'Y'">
              <h3 class="tournInfoBold">
                  {{ tourinfo.winner_name }}
              </h3>
            </b-col>
          </b-row>
          <b-row v-if="tourinfo.reports === ''"> </b-row>
          <b-row
            class="reportRow hidereportsdesk"
            v-else-if="Array.isArray(tourinfo.reports.reports_entry)"
          >
            <b-col class="reportTabs">
              <b-nav-item
                class="reportButton"
                :to="{
                  name: 'reports',
                  query: { url: reports.report_url, id: id, code: course_code, title: reports.report_title },
                }"
                v-for="reports in tourinfo.reports.reports_entry.slice(0, 3)"
                :key="reports"
              >
                {{ reports.report_title }}
              </b-nav-item>
            </b-col>
            <b-col class="reportTabs">
              <b-nav-item
                class="reportButton"
                :to="{
                  name: 'reports',
                  query: { url: reports.report_url, id: id, code: course_code, title: reports.report_title },
                }"
                v-for="reports in tourinfo.reports.reports_entry.slice(3, 6)"
                :key="reports"
              >
                {{ reports.report_title }}
              </b-nav-item></b-col
            >
            <b-col class="reportTabs">
              <b-nav-item
                class="reportButton"
                :to="{
                  name: 'reports',
                  query: { url: reports.report_url, id: id, code: course_code, title: reports.report_title },
                }"
                v-for="reports in tourinfo.reports.reports_entry.slice(6, 9)"
                :key="reports"
              >
                {{ reports.report_title }}
              </b-nav-item></b-col
            >
            <b-col class="reportTabs">
              <b-nav-item
                class="reportButton"
                v-for="reports in tourinfo.reports.reports_entry.slice(9, 10)"
                :key="reports"
              >
                {{ reports.report_title }}
              </b-nav-item></b-col
            >
          </b-row>
          <b-row v-else class="hidereportsdesk">
            <b-nav-item
              class="reportButton"
              :to="{
                name: 'reports',
                query: {
                  url: tourinfo.reports.reports_entry.report_url,
                  id: id,
                  code: course_code,
                  title: tourinfo.reports.reports_entry.report_title
                },
              }"
              :key="reports"
            >
              {{ tourinfo.reports.reports_entry.report_title }}
            </b-nav-item>
          </b-row>
        </b-col>
      </b-row>
        <b-row v-if="tourinfo.tourn_desc_found == 'Y'" class="headTitleRow topMiddleBlock" style="margin-top:3em; margin-bottom: 3em;">
          <b-col class="abovehbh">
            <h2 class="hbh">Overview</h2>
              <span class="underLine" v-html="tourinfo.tourn_desc">
              </span>
          </b-col>
        </b-row>
    </div>
    <div class="reportSection pad">
          <b-row v-if="tourinfo.reports === ''"> </b-row>
          <b-row
            class="reportRow hidereportsmob"
            v-else-if="Array.isArray(tourinfo.reports.reports_entry)"
          >
            <b-col lg='3' class="reportTabs" v-for="reports in tourinfo.reports.reports_entry"
                :key="reports">
              <b-nav-item
                class="reportButton"
                :to="{
                  name: 'reports',
                  query: { url: reports.report_url, id: id, code: course_code, title: reports.report_title },
                }"
                v-if="reports.report_title !== 'Scoring Statistics' && reports.report_title !== 'Course Statistics'  && reports.report_title !== 'Live Scoring Monitor' && reports.report_title !== 'Statistics Tables'"
              >
                {{ reports.report_title }}
              </b-nav-item>
            </b-col>
          </b-row>
          <b-row v-else class="hidereportsmob">
            <b-nav-item
              class="reportButton"
              :to="{
                name: 'reports',
                query: {
                  url: tourinfo.reports.reports_entry.report_url,
                  id: id,
                  code: course_code,
                  title: tourinfo.reports.reports_entry.report_title
                },
              }"
              :key="reports"
            >
              {{ tourinfo.reports.reports_entry.report_title }}
            </b-nav-item>
          </b-row>
    </div>
    <div class='pad'>
      <b-row :class="courseStats">
        <b-col>
          <table class="courseinfo">
            <thead>
              <tr class="title">
                <th>
                  Hole
                </th>
                <th>
                  1
                </th>
                <th>
                  2
                </th>
                <th>
                  3
                </th>
                <th>
                  4
                </th>
                <th>
                  5
                </th>
                <th>
                  6
                </th>
                <th>
                  7
                </th>
                <th>
                  8
                </th>
                <th>
                  9
                </th>
                <th>
                  out
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Par
                </td>
                <td
                  v-for="number in tourinfo.course_par.split(',').slice(0, 9)"
                  :key="number"
                >
                  {{ number }}
                </td>
                <td>
                  {{ tourinfo.course_out_par.slice(1, 5) }}
                </td>
              </tr>
              <tr>
                <td>
                  Yards
                </td>
                <td
                  v-for="number in tourinfo.course_length_yards
                    .split(',')
                    .slice(0, 9)"
                  :key="number"
                >
                  {{ number }}
                </td>
                <td>
                  {{ outVal(tourinfo.course_length_yards) }}
                </td>
              </tr>
              <tr>
                <td>
                  Meters
                </td>
                <td
                  v-for="number in tourinfo.course_length_meters
                    .split(',')
                    .slice(0, 9)"
                  :key="number"
                >
                  {{ number }}
                </td>
                <td>
                  {{ outVal(tourinfo.course_length_meters) }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
        <b-col>
          <table class="courseinfo">
            <thead>
              <tr class="title">
                <th>
                  10
                </th>
                <th>
                  11
                </th>
                <th>
                  12
                </th>
                <th>
                  13
                </th>
                <th>
                  14
                </th>
                <th>
                  15
                </th>
                <th>
                  16
                </th>
                <th>
                  17
                </th>
                <th>
                  18
                </th>
                <th>
                  In
                </th>
                <th>
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  v-for="number in tourinfo.course_par.split(',').slice(9, 18)"
                  :key="number"
                >
                  {{ number }}
                </td>
                <td>
                  {{ tourinfo.course_in_par.slice(1, 5) }}
                </td>
                <td>
                  {{ tourinfo.course_total_par }}
                </td>
              </tr>
              <tr>
                <td
                  v-for="number in tourinfo.course_length_yards
                    .split(',')
                    .slice(9, 18)"
                  :key="number"
                >
                  {{ number }}
                </td>
                <td>
                  {{ inVal(tourinfo.course_length_yards)}}
                </td>
                <td>
                  {{ totalVal(tourinfo.course_length_yards) }}
                </td>
              </tr>
              <tr>
                <td
                  v-for="number in tourinfo.course_length_meters
                    .split(',')
                    .slice(9, 18)"
                  :key="number"
                >
                  {{ number }}
                </td>
                <td>
                  {{ inVal(tourinfo.course_length_meters) }}
                </td>
                <td>
                  {{ totalVal(tourinfo.course_length_meters) }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </div>
    <div id="footer">
        <Footer :white="white" :config="config"/>
    </div>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
import Footer from "@/components/footer.vue";

export default {
  name: "Tournament",
  props: ["course_code", "id", "code", "config"],
  components: {
    Footer
  },
  data() {
    return {
      tourinfo: [],
      splash: "splash",
      splashText: "splashText",
      logo: "logo",
      overlay: "overlay",
      courseStats: "courseStats",
      white:true,
    };
  },
  methods:{
    outVal(value) {
      var split = value.split(",");
      var front9 = split.slice(0, 9);
      for (var i = 0; i < front9.length; i++) front9[i] = +front9[i];
      var sum = front9.reduce(function(a, b) {
        return a + b;
      }, 0);
      return sum;
    },
   inVal(value) {
      var split = value.split(",");
      var back9 = split.slice(9, 18);
      for (var i = 0; i < back9.length; i++) back9[i] = +back9[i];
      var sum = back9.reduce(function(a, b) {
        return a + b;
      }, 0);
      return sum;
    },
    totalVal(value) {
      var split = value.split(",");
      for (var i = 0; i < split.length; i++) split[i] = +split[i];
      var sum = split.reduce(function(a, b) {
        return a + b;
      }, 0);
      return sum;
    },
  },
  created() {
    apiCall
      .tmParams(this.id, this.course_code)
      .then(({ data }) => {
        this.DEFIMAGESAMS3 = data.tm_params.DEFIMAGESAMS3;
        this.tourinfo = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
.bkimageTourn {
  height: 300px;
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  background-position: center;
}
.row.reportRow.hidereportsmob {
  margin-top: 0px;
  padding-bottom: 30px;
}
.pad{
  padding: 0 50px;
}
.reportTabs {
  margin-left: -3px;
}
h3.tournInfoBold {
  font-size: 20px;
  padding-bottom: 0;
  line-height: 1.25;
  margin-top: 5px;
  color: #24292e;
  font-weight: 400;
  margin-left: -4px;
}
button.FullSchedule {
  background: #EB0A1E;
  border: none;
  color: #fff;
  padding: 10px 50px 10px 50px;
  float: right;
  margin-top: 3.2em;
  margin-right: 1em;
  font-size: 11pt;
  width: 300px;
}
button.FullSchedule:hover{
  background-color: #B91B29;
  width: 300px;
}
.nav-link:hover{
  background-color: #B91B29;
}
.splash {
  width: 100%;
  padding: 10px 17px 10px 17px;
}

.underLine {
  font-size: 20px;
  padding-bottom: 0;
  line-height: 1.25;
  margin-top: 5px;
  color: #24292e;
  font-weight: 700;
  margin-left: -4px;
}

.courseinfo {
  width: 100%;
}

.MainInfo {
  padding-left: calc(0vw + 3vh);
  padding-top: calc(2vw + 2vh);
  padding-right: calc(0vw + 3vh);
  padding-bottom: calc(2vw + 2vh);
}

.nav-link {
  background-color: #EB0A1E;
  color: white;
  text-align: center;
  /* width: 200px; */
}
.reportSection.pad {
  padding-left: 68px;
  padding-right: 54px;
}
.courseStats {
  /* padding-left: 4.5%;
  padding-right: 4%; */
  text-align: center;
  padding-bottom: 6%;
  /* background-color: #F9F8F8; */
  padding-top: 4%;
  margin: auto;
}

.title {
  background-color: #3E3E3E;
  color: white;
}

table,
th,
td {
  /* border: 1px solid black; */
}

.rowPad {
  padding-bottom: 1%;
}

.reportButton {
  font-size: calc(0.5vh + 0.5vw);
}

.mainImg {
  max-width: 75%;
}

#gmap_canvas {
  height: 300px;
  width: 350px;
}
#tourTitle {
  padding: 2%;
  font-size: 24px;
  padding-top: 2em;
  padding-bottom: 1.5em;
  padding-left: 3%;
  font-weight: 700;
}
#footer{
  padding-top: 2%;
}
.over{
  height: 300px;
  overflow: hidden;
}
.row.hidereportsdesk {
  width: 100%;
  display: block;
  padding-right: 0;
  margin-right: 0px;
  padding-left: 15px;
}
::v-deep th {
  padding-top: 10px;
}
@media only screen and (max-width: 1024px) {
  .splash {
    height: 300px;
  }
  .splashText {
    margin-top: -25%;
  }

  .logo {
    margin-left: -30%;
    padding-top: 20%;
  }
  .splashPad[data-v-14fba303] {
    padding-top: 8%;
  }
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.3em;
  }
  h4 {
    font-size: 1em;
  }
  .desc {
    margin-left: -100%;
  }
}
@media only screen and (max-width: 425px) {
  .hidereportsmob {
    display: none!important;
  }
}
@media only screen and (min-width: 426px) {
  .hidereportsdesk {
    display: none!important;
  }
}

@media only screen and (max-width: 480px) {
  #tourTitle {
    padding: 2%;
    font-size: 24px;
    padding-left: 3%;
    position: relative;
    top: 20px;
    width: 400px;
  }
  .nav-link[data-v-14fba303] {
    background-color: #EB0A1E;
    color: white;
    text-align: center;
    width: 100%;
  }
  ::v-deep .colwidth-mob {
    flex-basis: inherit!important;
    display: block;
  }
  .col {
    flex-basis: auto!important;
  }
  .titleandBtn {
    padding: 16px;
  }
  button.FullSchedule[data-v-14fba303] {
    margin-right: 0;
    width: 100%;
  }
  .fullnameMob {
    display: contents;
  }
  .pad {
    padding: 0 0px!important;
  }
  .splash {
    height: 175px;
  }
  .splashText {
    margin-top: 0;
    text-align: center;
  }
  .overlay {
    flex-basis: auto;
    background-color: #EB0A1E;
    color: white;
  }
  .logo {
    width: 50%;
    padding-top: 5%;
    padding-bottom: 5%;
    margin-left: 0;
  }
  h2 {
    font-size: 1.7em;
  }
  h3 {
    font-size: 1.5em;
  }
  h4 {
    font-size: 1.3em;
  }
  .reportButton {
    font-size: 1em;
  }
  .reportRow {
    padding-bottom: 10%;
  }
}
@media only screen and (max-width: 500px) {
  .over {
    height: auto!important;
    overflow: hidden;
  }
  .row.hidereportsdesk {
    padding-left: 0px;
  }
  .courseStats {
    overflow: scroll;
  }
}
</style>
